<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:height-200+'px',overflowY:'auto'}">
      <FormItem label="计划名称" prop="title">
        <Input v-model="form.title" placeholder="计划名称"></Input>
      </FormItem>
      <FormItem label="类型" prop="type">
        <Select v-model="form.type" placeholder="类型">
          <Option v-for="(item,index) in baseData['子计划检查类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="服务时间" prop="date">
        <ys-date-picker type="daterange" v-model="form.date" style="width: 100%" :placeholder="'计划服务时间'"/>
      </FormItem>
      <FormItem label="检查专家" prop="expertName">
        <Input readonly v-model="form.expertName" placeholder="检查专家">
          <Button type="primary" @click="expertFlag=true" slot="append">选择专家</Button>
        </Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save('onlySave')">{{form.id?'确定并保存':'仅保存'}}</Button>
      <!-- <Button type="primary" v-if="!form.id" @click="save('saveAndNew')">保存并新增检查任务</Button> -->
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="expertFlag" :width="1200" title="选择专家">
      <ExpertsComponent @on-ok="getExperts"></ExpertsComponent>
    </ys-modal>
  </div>
</template>

<script>
import edit from '@/mixins/edit.js'
import {subPlanInfo} from "@/api/plan";
import ExpertsComponent from "@/components/experts.vue";

export default {
  name: '',
  mixins: [edit],
  components: {
    ExpertsComponent
  },
  props: {
    policyID: {
      type: [Number, String],
      default: null
    },
    servePlanId: {
      type: [Number, String],
      default: null
    },
  },
  data() {
    return {
      expertFlag: false,
      categoryList: [],
      form: {
        date: [],
        startAt: '',
        orgId: '',
        checkId: '',
        policyId: '',
        type: 2,
        finishAt: '',
        title: '',
        expertName: '',
        expertList: [],
        categoryList: [],
        servePlanId: '',
        id: '',
      },
      ruleValidate: {
        title: [
          {required: true, message: '请填写计划名称', trigger: 'blur',}
        ],
        date: [
          {pattern: /\S/, required: true, message: '请选择服务时间', trigger: 'blur'},
        ],
      }
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    this.form.warehouseId = this.midData[0]?.warehouseId || this.warehouseId
    if (!this.mid) {
      this.form.orgId = this.userInfo.org_id;
      this.form.policyId = this.policyId || this.midData[0]?.policyId || ''
      this.form.servePlanId = this.midData[0]?.id ||this.servePlanId || ''
    } else {
      subPlanInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
        this.form.date = [this.form.startAt, this.form.finishAt];
        this.form.expertList = (res.data.expertList || []).map((item) => item.expertId);
        this.form.expertName = (res.data.expertList || []).map((item) => item.iname).join('，');
        this.form.categoryList= (res.data.categoryList || []).map((item) => item.categoryId)
      })

    }
  },
  methods: {
    save(mode = '') {
      this.form.startAt = this.form.date[0];
      this.form.finishAt = this.form.date[1];
      switch(mode) {
        case 'onlySave':
          this.ok('/servePlan/subPlan/AddOrEdit');
          break;
        case 'saveAndNew':
          this.ok('/servePlan/subPlan/AddOrEdit', 'azxFlag');
          break;
      }
    },

    getExperts(data) {
      this.form.expertList = data.map(item => item.id);
      this.form.expertName = data.map(item => item.iname).join('，');
    }
  }


}
</script>
<style lang="less" scoped>

</style>
