<template>
  <div>
    <ys-page-list
        ref="table"
        action="policy/List"
        :IDs="IDs"
        :isEmptySelect="isEmptySelect"
        :multiColumn="false"
        :params="params"
        :tableHeight="height-260"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="所在地区" prop="region" show>
          <ys-region changeOnSelect v-model="params.region" @on-change="getRegion"></ys-region>
        </FormItem>
        <FormItem label="服务开始日期" prop="serveStart">
          <ys-date-picker v-model="params.serveStart" clearable style="width: 120px" placeholder="服务开始日期"></ys-date-picker>
        </FormItem>
        <FormItem label="服务结束日期" prop="serveEnd">
          <ys-date-picker v-model="params.serveEnd" clearable style="width: 120px" placeholder="服务结束日期"></ys-date-picker>
        </FormItem>
        <FormItem label="保单服务状态" prop="isServe" show>
          <Select v-model="params.isServe" transfer clearable placeholder="保单服务状态" style="width: 120px;">
            <Option v-for="(item,index) in baseData['保单服务状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="保单生效状态" prop="activeStatus">
          <Select v-model="params.activeStatus" transfer clearable placeholder="保单生效状态" style="width: 100px;">
            <Option v-for="(item,index) in baseData['保单生效状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="保单状态" prop="status" show>
          <Select v-model="params.status" transfer clearable placeholder="保单状态" style="width: 100px;">
            <Option v-for="(item,index) in baseData['保单状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="项目状态" prop="projectStatus">
          <Select v-model="params.projectStatus" transfer clearable placeholder="项目状态" style="width: 100px;">
            <Option v-for="(item,index) in baseData['项目进度']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword" show>
          <Input placeholder="保险公司名称" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import components from '@/mixins/components';

export default {
  name: 'pro-component',
  mixins: [pageList, components],
  computed: {},
  props: {
    IDs: {type: Array, default: () => []},
    type: {type: Number, default:null},
  },
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {minWidth: 180, title: '保单号', key: 'number', align: 'left', renderConfig: {type: 'gotoDetail'}},
        // {width: 80, title: '保单状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '保单状态'}},
        {width: 110, title: '保单类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '保单类型'}},
        {width: 100, title: '共保体保单', key: 'isCoIns', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 130, title: '保费(元)', key: 'premiumCost', align: 'left'},
        {width: 130, title: '保额(元)', key: 'insureAmount', align: 'left'},
        {minWidth: 180, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 120, title: '项目工程类型', key: 'projectCategory', align: 'center', renderConfig: {type: 'baseData', parentName: '项目类型'}},
        {width: 220, title: '投保单位', key: 'enterpriseName', align: 'left'},
        {width: 220, title: '承保单位', key: 'companyName', align: 'left'},
        {width: 120, title: '应服务次数', key: 'serverCount', align: 'left'},
        {width: 170, title: '项目所在地', key: 'address', align: 'left'},
        {width: 100, title: '项目状态', key: 'projectStatus', align: 'center', renderConfig: {type: 'baseData', parentName: '项目进度'}},
        {width: 100, title: '服务状态', key: 'isServe', align: 'center', renderConfig: {type: 'baseData', parentName: '保单服务状态'}},
        {width: 80, title: '保单生效状态', key: 'activeStatus', align: 'center', renderConfig: {type: 'baseData', parentName: '保单生效状态'}},
      ],
      params: {
        status: '',
        region: '',
        countyCode: '',
        cityCode: '',
        projectStatus: '',
        serveStart: '',
        isServe: '',
        serveEnd: '',
        serveNum: null,
        provinceCode: '',
        orgId: '',
        keyword: '',
      },
    }
  },
  mounted() {
    this.params.type = this.type;
    this.getList();
  },
  methods: {
    getRegion(data) {
      this.params.countyCode = data[2] ? data[2].code : '';
      this.params.cityCode = data[1] ? data[1].code : '';
      this.params.provinceCode = data[0] ? data[0].code : '';
    },
  }
}
</script>
