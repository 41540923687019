<template>
  <div>
    <Form ref="form" class="editForm " :model="form" :rules="ruleValidate" :label-width="70">
      <FormItem label="原状态" prop="status">
        <Select v-model="status" disabled placeholder="原状态">
          <Option v-for="(item, index) in baseData['专项任务状态']" :value="item.value" :key="index"> {{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="新状态" prop="status">
        <Select v-model="form.status" disabled placeholder="任务状态">
          <Option v-for="(item, index) in baseData['专项任务状态']" :value="item.value" :key="index"> {{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="原因" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 5}" placeholder="请输入更改原因"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/check/EditStatus')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import edit from '@/mixins/edit'

export default {
  name: 'status',
  mixins: [edit],
  props:{
    status:{type:Number,default:null}
  },
  data() {
    return {
      ruleValidate: {
        desc: [
          {required: true, message: '请输入更改原因', trigger: 'blur'}
        ],
      },
      form: {
        id: '',
        desc: '',
        status: 5,
      }
    }
  },
  async mounted() {
    this.form.id = this.mid;
  },
  methods: {}
}
</script>
<style lang="less" scoped>
</style>
