<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="100">
      <FormItem label="检查结论" prop="desc">
        <Input type="textarea" v-model="form.desc" :autosize="{minRows: 5,maxRows: 8}" placeholder="输入检查结论"></Input>
        <Tag @click.native="checkDesc" style="cursor: pointer">模板一</Tag>
      </FormItem>
      <FormItem label="整改建议" prop="suggestion">
        <Row>
          <Col span="22">
            <Input type="textarea" style="width: 100%;word-break: break-all;" v-model="form.suggestion" :autosize="{minRows: 5,maxRows: 8}" placeholder="输入整改建议"></Input>
          </Col>
          <Col span="2">
            <Button type="primary" style="width: 100%;height: 100%;" @click="quickInfoShow = true">选择</Button>
          </Col>
        </Row>
      </FormItem>
      <!-- <FormItem label="整改建议措施" prop="suggestion">
        <Poptip placement="bottom" :width="800" trigger="hover" title="请选择整改建议措施" style="width: 100%">
          <Input type="textarea" style="width: 660px;" v-model="form.suggestion" :autosize="{minRows: 5,maxRows: 8}" placeholder="输入整改建议措施"></Input>
          <template #content>
            <ul class="suggestion">
              <li v-for="(item,index) in suggestionList" @click="choice(item.id)" :key="item.id">{{ index + 1 }}、{{ item.text }}</li>
            </ul>
          </template>
        </Poptip>
      </FormItem> -->
      <FormItem label="上传附件" prop="fileList">
        <ys-upload :action="'/web/resource/UploadFile'" :defaultList="detail.fileList||[]" @on-success="bLFile" :headers="{token:token}" type="halfList"></ys-upload>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('report/Add')">{{ title }}</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="quickInfoShow" :width="1000" :title="'选择整改建议'">
      <QuickInfo :orgId="midData.length===1?midData[0].serveOrgId:''" :type="2" :status="1" @on-ok="quickInfoData"></QuickInfo>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {getReportInfo, getReportScoreInfo} from "@/api/report";
import {getBaseDataName} from 'ys-admin'
import QuickInfo from "@/components/quickInfo.vue";
import {getCheckInfo} from "../../../api/task";
import {policyInfo} from "../../../api/policy";

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    checkId: {type: Number, default: null},
    title: {type: String, default: '确定并保存'},
    taskType: {type: String, default: '1'}, // 任务类型：1为专项任务；2为安责险任务
  },
  components: {
    QuickInfo
  },
  data() {
    return {
      ruleValidate: {
        desc: [
          {required: true, type: 'string', message: '请填写检查结论', trigger: 'blur'},
          {required: true, type: 'string', message: '请填写检查结论', trigger: 'change'}
        ],
      },
      quickInfoShow: false,
      checkItemFlag: false,
      result: '',
      detail: {},
      suggest: [],
      form: {
        id: '',
        type: '',
        checkId: '',
        fileList: [],
        suggestion: '',
        desc: '',
      },
    }
  },
  async mounted() {
    this.form.checkId = this.checkId;
    await this.getDetail();
    if (!this.detail.id && this.taskType === '2') this.checkItemFlag = true;
  },
  methods: {
    quickInfoData(data) {
      this.form.suggestion = '根据本次安全检查发现的问题，建议总承包方牵头组织采取以下措施: ';
      data.forEach((item, index) => {
        let desc = item.desc
        if (desc.slice(-1) === '；' || desc.slice(-1) === '。') {
          desc = desc.substr(0, desc.length - 1)
        }
        this.form.suggestion = this.form.suggestion + '\n' + (index + 1) + '、' + desc + ';'
      })
    },
    bLFile(data) {
      this.detail.fileList = data;
      this.form.fileList = data.map(item => item.id);
    },
    async getDetail() {
      this.modal.spinShow = true;
      await getCheckInfo({id: this.checkId}).then((res) => {
        this.detail.check = res.data;
        this.form.type=res.data.type
      });
      await policyInfo({id: this.detail.check.policyId}).then((res) => {
        this.detail.policy = res.data;
      });
      await getReportScoreInfo({checkId: this.checkId}).then((res) => {
        this.result = res.data.result || '';
        this.detail.categoryList = res.data.categoryList;
      });
      if (this.mid) {
        await getReportInfo({id: this.mid}).then((res) => {
          this.detail = res.data;
          this.form.fileList = (this.detail.fileList || []).map(item => item.id);
          this.form.desc = res.data.desc;
          this.form.suggestion = res.data.suggestion
        })
      }
    },
    checkDesc() {
      if (this.form.desc) {
        this.$Modal.confirm({
          title: '提示',
          content: '检查结论已经存在，是否要覆盖？',
          okText: '覆盖',
          cancelText: '取消',
          onOk: () => {
            this.mode()
          }
        })
      } else {
        this.mode();
      }
    },
    mode() {
      const {categoryList = [], check, commentList, policy} = this.detail;
      let comment = '', commentCount = '', riskGradeCount = '';
      this.baseData['风险等级'].forEach((item) => {
        let currentCommentList = (commentList || []).filter((subItem) => {
          return item.value === subItem.riskGrade
        })
        riskGradeCount = riskGradeCount + item.name + '类：' + currentCommentList.length + '项，';
      })
      categoryList.forEach((item) => {
        let currentCommentList = (commentList || []).filter((subItem) => {
          return item.id === subItem.categoryId
        })
        if (currentCommentList.length > 0) {
          commentCount = commentCount + item.iname + '类：' + currentCommentList.length + '点位，';
          // 将所有依据拿出来，判断一下是否每一条依据解为是否都有其他标点符号，有则无需手动加直接拼接即可
          const SymbolList = [';', '；', '。', '.', '、', ':', '：', ',', '，']
          const remarksRiskList = currentCommentList.map((i) => i.remarksRisk)
          const isSymbol = remarksRiskList.every(item => {
            return SymbolList.some(SymbolItem => SymbolItem === item.slice(-1))
          })
          // 拼接
          comment = comment + item.iname + '类：' + isSymbol ? remarksRiskList.join('') : remarksRiskList.join('、') + '；'
        }
      })
      this.form.desc = '我司根据' + policy.companyName + '安责险风控服务的要求，于' + this.$formatDate(check.signTime, 'yyyy年MM月dd日') + '对' + check.projectName + '进行第 ' + (this.detail.numberOfTimes || 1) + ' 次安全生产责任险的隐患排查服务工作。本工程目前主要进行' + (check.project?.progress ? getBaseDataName('施工阶段', check.project.progress, false) : '') + '，根据' + check.warehouseName + '，结合工程现阶段的施工情况，对工程现场的' + check.categoryList.map((item) => {
          return item.iname
        }).join('、') + '等几大类的保证项目和一般项目进行了安全隐患的排查服务工作。' + comment +
        '\n本次施工现场安全隐患排查发现的有代表性的安全隐患点共 ' + (commentList || []).length + ' 处，' + (commentCount ? '其中' + commentCount : '') + '根据隐患的风险程度等级，' + riskGradeCount + '本次安全隐患排查所提出的安全隐患不限于上述问题，所排查的是在施工中易于发生安全隐患且有代表性的几个大类，需施工单位根据目前的工程进度和工作内容，有针对性的开展安全检查，对发现的安全隐患及时进行全面整改保证施工生产安全，杜绝安全事故的发生。根据“3.1检查评定等级划分”规定的标准，结合现场实际情况本次排查的有代表性的施工安全几个大类，施工安全评价为"' + this.result + '"。'
    },
  }
}
</script>

<style lang="less" scoped>
.suggestion {

  li {
    padding: 7px 5px;
    width: 768px;
    white-space: break-spaces;
    border-bottom: 1px solid #ececec;
    cursor: pointer;

    &:hover {
      background-color: #efefef;
    }
  }
}
</style>
