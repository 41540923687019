<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" :label-width="100">
      <FormItem label="项目名称" prop="projectName" style="width: 100%">
        <Input v-model="form.projectName" disabled placeholder="项目名称"></Input>
      </FormItem>
      <FormItem label="巡查人员" prop="createdBy" style="width: 100%">
        <Select v-model="form.createdBy" label-in-value filterable @on-change="createdChange" placeholder="巡查人员">
          <Option v-for="item in expertList" :label="item.iname" :value="item.userId" :key="item.id">{{ item.iname }}</Option>
        </Select>
      </FormItem>
      <!-- <FormItem label="巡检日期" prop="serveTime">
        <ys-date-picker type="datetime" v-model="form.serveTime" style="width: 100%;" placeholder="巡检日期"></ys-date-picker>
      </FormItem> -->
      <FormItem label="备注" prop="riskRemarks" style="width: 100%">
        <Input v-model="form.riskRemarks" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 2,maxRows: 3}" placeholder="输入备注"></Input>
      </FormItem>
    </Form>
    <h1 class="h1Title">风险记录</h1>
    <ProblemsList @on-ok="getData" :header="false" :detailInner="true" :footer="false" :multiColumn="false" :tableHeight="height-450" ref="problems" :mid="checkID" v-if="checkID"></ProblemsList>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(form.id ? '/amend/Edit' : '/amend/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit';
import {getCheckInfo} from "@/api/task";
import {getAmendInfo} from "@/api/task";
import ProblemsList from "@/views/task/amend/edit/problem";
import {formatDate} from 'ys-admin'
import {checkExpertList} from "@/api/expert";

export default {
  name: 'rectificationsEdit',
  mixins: [edit],
  components: {
    ProblemsList,
  },
  props: {
    taskType: {type: String, default: '1'}, // 任务类型：1为专项任务；2为安责险任务
  },
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填改建议书标题', trigger: 'blur'}
        ],
        deadline: [
          {type: 'string', required: true, message: '整改截止日不能为空', trigger: 'change'}
        ],
      },
      amendInfo: {},
      detail: {},
      expertList: [],
      form: {
        iname: '',
        id: '',
        expertName: '',
        checkId: '',
        createdBy: '',
        createdUser: '',
        projectId: '',
        projectName: '',
        serveTime: new Date(),
        deadline: new Date(new Date().getTime() + 3600 * 24 * 1000 * 7),
        commentList: [],
        riskRemarks: '',
      },
    }
  },
  computed: {
    checkID() {
      return this.parentID ? this.parentID : this.form.checkId
    }
  },
  mounted() {
    this.form.deadline = formatDate(new Date(new Date().getTime() + 3600 * 24 * 1000 * 7))
    this.getExpert();
    if (this.mid) {
      getAmendInfo({id: this.mid}).then((res) => {
        this.amendInfo = res.data
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
        this.getSpecialInfo(this.form.checkId);
      })
    } else {
      this.getSpecialInfo(this.parentID);
    }
  },
  methods: {
    getExpert() {
      checkExpertList({checkId: this.checkID, limit: -1}).then(res => {
        this.expertList = res.data.list || [];
        this.form.createdBy = this.expertList.length > 0 ? this.expertList[0].userId : ''
        this.form.createdUser = this.expertList.length > 0 ? this.expertList[0].iname : ''
      })
    },
    createdChange(data) {
      this.form.createdBy = data.value;
      this.form.createdUser = data.label;
    },
    getData(data) {
      this.form.commentList = (data.data.list || []).map(item => item.id);
    },
    getSpecialInfo(id) {
      getCheckInfo({id: id}).then((res) => {
        this.detail = res.data
        this.form.projectId = this.detail.projectId;
        this.form.projectName = this.detail.projectName;
        this.form.iname = this.detail.iname + '的整改单';
        this.form.checkId = this.detail.id;
        this.form.expertName = this.detail.expertList.map((item) => item.iname).join();
      })
    },

  }
}
</script>
