import {post, get} from 'ys-admin'

/**
 * 查询服务计划详情
 * @param {Object} params
 * @returns {Object}
 */
export const planInfo = function (params) {
  return get('/servePlan/GetInfo', params)
};

/**
 * 查询服务子计划列表
 * @param {Object} params
 * @returns {Object}
 */
export const subPlanList = function (params) {
  return get('/servePlan/subPlan/List', params)
};

/**
 * 查询服务子计划详情
 * @param {Object} params
 * @returns {Object}
 */
export const subPlanInfo = function (params) {
  return get('/servePlan/subPlan/GetInfo', params)
};

/**
 * 关联子计划
 * @param {Object} params
 * @returns {Object}
 */
export const bindCheck = function (params) {
  return post('/servePlan/subPlan/SelectCheck', params)
};

/**
* 查询总计划表
* @param {Object} params
* @returns {Object}
*/
export const servePlanList = function (params) {
  return get('/servePlan/List', params)
};
 
/**
* 子计划解绑
* @param {Object} params
* @returns {Object}
*/
export const subPLanUnSelectCheck = function (params) {
  return post('/servePlan/subPlan/UnSelectCheck', params)
};
/**
* 子计划解绑演示任务等
* @param {Object} params
* @returns {Object}
*/
export const subPLanUnSelectEvent = function (params) {
  return post('/servePlan/subPlan/UnSelectEvent', params)
};

/**
* 子计划解绑验收任务
* @param {Object} params
* @returns {Object}
*/
export const subPLanUnSelectInspects = function (params) {
  return post('/servePlan/subPlan/UnSelectInspects', params)
};