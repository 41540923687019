<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/comment/List"
        :params="params"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :header="false"
        :footer="false"
        @on-ok="$emit('on-ok',$event)"
        :multiColumn="multiColumn"
        :detailInner="detailInner"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="风险等级" prop="riskGrade">
          <Select v-model="params.riskGrade" clearable placeholder="风险等级" style="width: 100px;">
            <Option v-for="(item,index) in baseData['风险等级']" :style="{'color':item.color }" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" style="width: 120px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <Button type="primary" @click="add" :disabled="midData.length===1&&midData[0].status!==5">新增</Button>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1200" :title="(tableSelectItem.length>0?'修改':'新增')+'风险'">
      <Edit @on-ok="getList" :parentID="mid" :midData="midData" :mid="detailID"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import Edit from "@/views/task/problems/Edit.vue";
import Detail from "@/views/task/problems/detail.vue";

export default {
  name: 'problems',
  mixins: [pageList],
  props: {
    type: {type: String, default: ''},
    amendId: {type: [Number, String], default: null},
    projectId: {type: [Number, String], default: null},
    categoryId: {type: [Number, String], default: null},
    dictId: {type: [Number, String], default: null},
    itemId: {type: [Number, String], default: null},
    amendType: {type: [Number], default: null},
    amendStatus: {type: [Number], default: null},
    status: {type: [Number], default: null},
    riskGrade: {type: [Number], default: null},
    detailInner: {type: [Boolean], default: false},
    multiColumn: {type: [Boolean], default: true},
  },
  components: {
    Edit,
    Detail,
  },
  data() {
    return {
      checkFlag: false,
      params: {
        checkId: '',
        riskGrade: '',
        type: '',
        amendId: '',
        projectId: '',
        categoryId: '',
        dictId: '',
        itemId: '',
        amendType: '',
        amendStatus: '',
        status: '',
        keyword: '',
      },
      headerColumns: [
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 220, title: '风险项目', key: 'itemName', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 120, title: '检查项目', key: 'categoryName', align: 'left'},
        {width: 100, title: '检查专家', key: 'expertName', align: 'center'},
        {width: 110, title: '发现时间', key: 'serveTime', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 130, title: '风险辨别(扣分项)', key: 'scope', align: 'center', render: (h, params) => {
            return h('span', String(params.row.scope === -1 ? '-' : params.row.scope))
          }},
        {width:80, title: '风险等级', key: 'riskGrade', align: 'center', renderConfig: {type: 'baseData', parentName: '风险等级'}},
        {minWidth: 230, title: '风险描述', tooltip: true, key: 'remarksText', align: 'left'},
      ]
    }
  },

  mounted() {
    this.params.checkId = this.mid;
    this.params.type = this.type;
    this.params.amendId = this.amendId;
    this.params.projectId = this.projectId;
    this.params.categoryId = this.categoryId;
    this.params.dictId = this.dictId;
    this.params.itemId = this.itemId;
    this.params.amendType = this.amendType;
    this.params.amendStatus = this.amendStatus;
    this.params.status = this.status;
    this.params.RiskGrade = this.riskGrade;
    this.params.orgId = this.userInfo.org_id;
    this.getList()
  }
}
</script>
