<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="check/List"
        :params="params"
        keyID="46676E3E596B11EC9C5C00163E15C775"
        :multiBtn="multiBtn"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="任务类型" prop="type" v-if="!type">
          <Select v-model="params.type" transfer placeholder="检查单类型" style="width: 120px;">
            <Option v-for="(item,index) in baseData['检查单类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="任务状态" prop="statusList">
          <Select v-model="params.statusList" class="selectMultiple" multiple transfer clearable placeholder="任务状态" style="width: 200px;" :max-tag-count="2">
            <Option v-for="(item,index) in baseData['专项任务状态'].filter(item => item.value !== 4)" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="项目名称" clearable style="width: 200px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <Button type="primary" @click="add">新增</Button>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="900" :title="(tableSelectItem.length===0?'新增':'修改')+'任务'">
      <Edit @on-ok="getList" :mid="detailID"></Edit>
    </ys-modal>
    <ys-modal v-model="statusFlag" :width="500" title="更改任务状态">
      <Status @on-ok="getList" :mid="detailID" :status="tableSelectItem.length===1?tableSelectItem[0].status:null"></Status>
    </ys-modal>
    <ys-modal v-model="warehouseFlag" :width="800" title="同步检查项">
      <changeWarehouse @on-ok="getList" :mid="detailID"></changeWarehouse>
    </ys-modal>
    <ys-modal v-model="rectFlag" :width="1000" title="生成整改单">
      <rectificationsAdd @on-ok="getList" :parentID="detailID" taskType="2"></rectificationsAdd>
    </ys-modal>
    <ys-modal v-model="addReportFlag" :width="800" :title="tableSelectItem.length===1&&tableSelectItem[0].reportId?'修改报告':'创建报告'">
      <ReportAdd
          @on-ok="getList"
          :checkId="detailID"
          :mid="tableSelectItem.length===1?tableSelectItem[0].reportId:''"
          :midData="tableSelectItem"
          :title="tableSelectItem.length===1&&tableSelectItem[0].reportId?'修改报告':'创建报告'" taskType="2"></ReportAdd>
    </ys-modal>
    <ys-modal :z-index="500" v-model="problemFlag" :width="1200" title="新增风险">
      <problemsAdd @on-ok="getList" :parentID="detailID" :midData="tableSelectItem"></problemsAdd>
    </ys-modal>
    <ys-modal :z-index="500" v-model="confirmFlag" title="确认任务">
      <confirm @on-ok="getList" :mid="detailID"></confirm>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/task/list/detail";
import Edit from "@/views/task/list/Edit.vue";
import Status from "@/views/task/list/status.vue";
import changeWarehouse from "@/views/task/list/changeWarehouse.vue";
import rectificationsAdd from "@/views/task/amend/Edit.vue";
import ReportAdd from "@/views/task/reports/edit.vue";
import problemsAdd from "@/views/task/problems/Edit.vue";
import Confirm from "@/views/task/list/confirm";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {Confirm, problemsAdd, rectificationsAdd, ReportAdd, changeWarehouse, Status, Detail, Edit},
  props: {
    type: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      params: {
        projectId: '',
        userId: '',
        tag: '',
        type: '',
        statusList: [],
        keyword: '',
      },
      rectFlag: false,
      addReportFlag: false,
      confirmFlag: false,
      warehouseFlag: false,
      problemFlag: false,
      statusFlag: false,
      putInFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {title: '保单编号', width: 250, key: 'number', align: 'left'},
        {width: 200, title: '任务名称', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {minWidth: 250, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 120, title: '任务类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '检查单类型'}},
        {width: 100, title: '含资料检查', key: 'hasDoc', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 120, title: '工程类型', key: 'projectCategory', align: 'center', renderConfig: {type: 'baseData', parentName: '项目类型'}},
        {width: 200, title: '施工单位', key: 'contractUnit', align: 'left'},
        {width: 120, title: '发布日期', key: 'publishAt', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 120, title: '计划服务时间', key: 'serveTime', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 120, title: '完成截止日', key: 'finishAt', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 170, title: '打卡时间', key: 'signTime', align: 'center', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}},
        {width: 170, title: '报告提交时间', key: 'reportPublishedAt', align: 'center', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}},
        {width: 170, title: '整改单提交时间', key: 'amendPublishedAt', align: 'center', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}},
        {width: 120, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '专项任务状态'}},
        {width: 170, title: '创建时间', key: 'createdAt', align: 'center'},
      ]
    }
  },
  computed: {
    multiBtn() {
      const {tableSelectItem = []} = this;
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              targetUrl: '/main/task/list/detail/' + (tableSelectItem.length === 1 ? tableSelectItem[0].id : null),
              icon: 'md-eye',
              disabled: tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '修改',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status > 3
            },
            {
              click: () => this.delItem('/check/Remove', {id: tableSelectItem[0].id}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status === 99
            },
          ]
        },
        {
          children: [
            {
              click: () => this.confirmFlag = true,
              title: '确认',
              icon: 'md-checkbox',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 2
            },
            {
              click: () => this.problemFlag = true,
              title: '新增风险',
              icon: 'md-add',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 5
            },
            {
              click: () => this.rectFlag = true,
              title: '生成整改单',
              icon: 'md-clipboard',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].amendId > 0
            },
            {
              click: () => this.addReportFlag = true,
              title: this.tableSelectItem.length && this.tableSelectItem[0].reportId ? '修改报告' : '创建报告',
              icon: 'ios-list-box-outline',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 5
            },
          ]
        },
        {
          children: [
            {
              click: () => this.statusFlag = true,
              title: '更改任务状态',
              icon: 'md-code-working',
              disabled: tableSelectItem.length !== 1 || tableSelectItem[0].status !== 99
            },
            {
              click: () => this.warehouseFlag = true,
              title: '强制更新检查标准',
              icon: 'md-code',
              disabled: tableSelectItem.length !== 1 || tableSelectItem[0].status === 99
            },
          ]
        },
      ]
    },
  },
  mounted() {
    this.params.type = this.type;
    this.getList();
  },
  methods: {}
}
</script>
