<template>
  <div>
    <Alert type="warning" v-if="form.status>1">已发布的任务将无法修改检查专家、检查标准和检查项！</Alert>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" :label-width="110" inline :style="{maxHeight:height-180+'px'}" style="overflow-y: auto">
      <FormItem label="检查项目" prop="projectId">
        <Input v-model="form.projectName" disabled placeholder="检查项目">
          <Button type="primary" @click="projectFlag=true" v-if="!form.id" slot="append">选择项目</Button>
        </Input>
      </FormItem>
      <FormItem label="检查单类型" prop="type">
        <Select v-model="form.type" :disabled="form.id>0" @on-change="typeChange">
          <Option v-for="(item,index) in baseData['检查单类型'].filter(item=>item.value<3)" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="服务计划" prop="policyPlanId">
        <Select v-model="form.policyPlanId" clearable placeholder="服务计划" transfer style="width: 90%;" @on-change="planChange" @on-clear="planClear">
          <Option v-for="(item, index) in subPlanList" :value="item.id" :key="item.id" :disabled="item.checkId > 0 && item.checkId !== form.id">[{{ item.checkId > 0 ? '已关联' : '未关联' }}]{{ item.title || `服务计划明细${index + 1}` }}</Option>
        </Select>
        <Button type="primary" icon="md-add" style="width: 10%;" @click="addPlan"></Button>
      </FormItem>
      <FormItem label="任务名称" prop="iname">
        <Input v-model="form.iname" placeholder="任务名称"></Input>
      </FormItem>
      <FormItem label="计划服务时间" prop="serveTime">
        <ys-date-picker style="width: 100%" placeholder="检查时间" v-model="form.serveTime"/>
      </FormItem>
      <FormItem label="截止完成日" prop="finishAt">
        <ys-date-picker style="width: 100%" placeholder="截止完成日" v-model="form.finishAt"/>
      </FormItem>
      <FormItem label="含资料检查" prop="hasDoc" style="width: 35%">
        <i-switch v-model="form.hasDoc" :true-value="1" :false-value="0">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
        <Poptip trigger="hover" placement="top-start" title="检查资料" width="600">
          <a href="javascript:" style="margin-left: 20px;">查看检查资料</a>
          <div slot="content">
            <Alert type="warning">请先选择检查项目！</Alert>
            <ys-page-list
                ref="table"
                :header="false"
                keyLabel="docCategoryId"
                size="small"
                :footer="false"
                :multiColumn="false"
                :tableData="docList"
                tableHeight="300"
                :headerColumns="headerColumns"/>
          </div>
        </Poptip>
      </FormItem>
      <FormItem label="开启审核" :label-width="70" prop="isCheckReport" style="width: 15%">
        <Tooltip content="开启后该任务的整改单和报告都需要组长审核！" max-width="600">
          <i-switch v-model="form.isCheckReport" :true-value="1" :false-value="0">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </Tooltip>
      </FormItem>
      <FormItem label="超危/危大工程" :label-width="120" prop="hasDanger" style="width: 15%">
        <i-switch v-model="form.hasDanger" :true-value="1" :false-value="0">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <FormItem label="检查标准" prop="warehouseId" style="width: 100%">
        <Select v-model="form.warehouseId" :disabled="form.status>1" clearable @on-change="getStandardInfo" placeholder="检查标准">
          <Option v-for="(item) in standardList" :value="item.id" :key="item.id"> {{ item.iname }}</Option>
        </Select>
      </FormItem>
      <FormItem label="检查项目" prop="categoryList" style="width: 100%">
        <CheckboxGroup v-model="form.categoryList">
          <Checkbox v-for="(item) in categoryList" :disabled="form.status>1" class="checkbox-border-item" border :label="item.id" :key="item.id"> {{ item.iname }}</Checkbox>
        </CheckboxGroup>
      </FormItem>
      <FormItem label="任务描述" prop="remarks" style="width: 100%">
        <Input v-model="form.remarks" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 5}" placeholder="输入任务描述"></Input>
      </FormItem>
      <h1 class="h1Title">检查专家</h1>
      <div v-for="(item,index) in (form.expertList||[])" :key="index">
        <FormItem label="专家姓名" style="width: 37%; display: inline-block" :prop="'expertList.'+index+'.iname'">
          <Input disabled v-model="item.iname" placeholder="专家姓名"/>
        </FormItem>
        <FormItem label="组长" :labelWidth="50" style="width: 12%; display: inline-block" :prop="'expertList.'+index+'.isLeader'">
          <i-switch :disabled="(lastLeader&&item.isLeader===1)||form.status>1" v-model="item.isLeader" :true-value="1" :false-value="0">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </FormItem>
        <FormItem label="本次费用" :labelWidth="90" style="width: 44%; display: inline-block" :prop="'expertList.'+index+'.cost'">
          <InputNumber v-model="item.cost" placeholder="本次费用" :disabled="form.status>1" :min="0" style="width: 100%"/>
        </FormItem>
        <Button :disabled="(form.expertList||[]).length === 1||form.status>1" icon="ios-close-circle-outline" @click="delExpert(index)" style="margin-left: 5px;margin-top:1px;"></Button>
      </div>
      <Button icon="md-add" style="margin-bottom: 10px;" :disabled="form.status>1" @click="expertFlag=true" long type="primary">新增专家</Button>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save('onlySave')">确定并保存</Button>
      <Button :disabled="(form.expertList||[]).length <= 0" v-if="form.status<2" type="primary" @click="save('saveAndRelease')">保存并发布</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="projectFlag" :width="1200" title="选择项目">
      <policy :limit="1" :type="1" @on-ok="getData"></policy>
    </ys-modal>
    <ys-modal v-model="expertFlag" :width="1200" title="选择专家">
      <MyExperts status="40" @on-ok="getExpertData"></MyExperts>
    </ys-modal>
    <ys-modal v-model="planFlag" :width="1000" :title="'新建服务计划'">
      <planEdit @on-ok="getPlan" :projectData="projectData"></planEdit>
    </ys-modal>
    <ys-modal v-model="subPlanFlag" :width="550" :title="'新建计划明细'">
      <subPlanEdit @on-ok="getSubPlan" :warehouseId="form.warehouseId" :servePlanId="form.servePlanId"></subPlanEdit>
    </ys-modal>
  </div>
</template>

<script>
import edit from '@/mixins/edit'
import {getStandardInfo, getStandardData, getDoc} from "@/api/common";
import {publicTask, getCheckInfo} from "@/api/task";
import {servePlanList, subPlanList} from "@/api/plan";
import {policyInfo} from "@/api/policy";
import {post, getBaseData} from "ys-admin";
import planEdit from "@/views/task/plan/edit.vue";
import subPlanEdit from "@/views/task/plan/detail/subPlan/edit.vue";

export default {
  name: 'taskEdit',
  mixins: [edit],
  components: {
    'policy': () => import('@/components/policy.vue'),
    'MyExperts': () => import('@/components/experts.vue'),
    planEdit,
    subPlanEdit,
  },
  props: {
    planData: {type: Array, default: () => []},
    type: {type: [String, Number], default: 1}
  },
  data() {
    return {
      ruleValidate: {
        projectId: [
          {required: true, message: '请选择需检查项目', trigger: 'blur', type: 'number'}
        ],
        iname: [
          {required: true, message: '请填写任务名称', trigger: 'blur'}
        ],
        warehouseId: [
          {required: true, message: '请选择检查标准', trigger: 'blur,change', type: 'number'}
        ],
        categoryList: [
          {required: true, message: '请至少选择一项检查项目', trigger: 'blur', type: 'array'}
        ]
      },
      headerColumns: [
        {width: 60, title: '排序', key: 'index', align: 'center'},
        {minWidth: 400, title: '需检查资料', key: 'name', align: 'left',},
      ],
      projectFlag: false,
      expertFlag: false,
      planFlag: false,
      subPlanFlag: false,
      standardList: [],
      docList: [],
      categoryList: [],
      expertsList: [],
      planList: [],
      subPlanList: [],
      projectData: [],
      form: {
        iname: '',
        status: 1,
        type: 2,
        isCheckReport: 0,
        hasDanger: 0,
        hasDoc: 0,
        ServeOrgId: '',
        warehouseId: '',
        projectId: '',
        projectName: '',
        id: '',
        remarks: '',
        serveTime: new Date(new Date().getTime() + 1000 * 3600 * 7),
        finishAt: new Date(new Date().getTime() + 30 * 24 * 3600 * 1000),
        categoryList: [],
        policyId: '',
        expertList: [],
        servePlanId: '', // 总计划id
        policyPlanId: '', // 子计划id
      }
    }
  },
  computed: {
    lastLeader() {
      return this.form.expertList.filter(item => item.isLeader === 1).length === 1
    }
  },
  async mounted() {
    this.$nextTick(() => {
      this.form.type = this.type
    })
    await this.getStandard()
    this.getInfo()
  },
  methods: {
    async getInfo() {
      if (this.mid) { // 编辑
        // 任务详情
        await getCheckInfo({id: this.mid}).then(res => {
          Object.keys(this.form).forEach((item) => {
            switch (item) {
              case 'categoryList':
                this.form.categoryList = (res.data.categoryList || []).filter(item => item.enable === 1).map((item) => item.id);
                break;
              case 'expertList':
                this.form.expertList = res.data.expertList || []
                break;
              default:
                this.form[item] = res.data[item]
                break;
            }
          })
          // 检查标准
          if (!this.standardList.some(item => item.id === this.form.warehouseId)) {
            this.$Notice.warning({
              title: '请知悉',
              desc: '当前计划所绑定的检查标准已被停用，请注意更改！'
            });
          }
        })
        // 当前项目检查资料
        this.getDocByProjectId()
        // 当前项目检查标准下检查项目
        this.getStandardInfo()
        // 当前项目服务计划明细列表
        this.getSubPlanList()
      } else { // 新增
        if (this.planData.length > 0) {
          await this.getData(this.planData)
          this.form.policyPlanId = this.planData[0].id
          this.form.servePlanId = this.planData[0].servePlanId
        } else {
          this.form.warehouseId = this.standardList.length > 0 ? this.standardList[0].id : ''
          await this.getStandardInfo()
        }
      }
    },
    // 检查标准
    async getStandard() {
      await getStandardData({limit: -1, status: 1}).then((res) => {
        this.standardList = res.data.list || [];
      })
    },
    // 检查项目
    async getStandardInfo(event) {
      // 手动切换检查标准时先清空数据
      if (event) {
        this.categoryList = []
        this.form.categoryList = []
      }
      await getStandardInfo({id: this.form.warehouseId, random: Math.ceil(Math.random() * 100)}).then(res => {
        this.categoryList = res.data.categoryList || [];
        // 默认编辑时当切换后再自动全选，或者新增时直接自动全选
        if (event || !this.mid) this.form.categoryList = this.categoryList.map((item) => item.id);
      })
    },
    // 检查资料
    async getDocByProjectId() {
      await getDoc({projectId: this.form.projectId}).then(res => {
        this.docList = res.data || [];
      })
    },
    // 服务总计划列表
    async getPlanList() {
      await servePlanList({orgId: this.userInfo.org_id, policyId: this.form.policyId}).then(res => {
        // 总服务计划id
        this.form.servePlanId = res.data?.list ? res.data.list[0].id : ''
      })
    },
    // 服务计划明细(子计划)列表
    async getSubPlanList() {
      if (!this.form.policyId) return;
      subPlanList({policyId: this.form.policyId, type: this.form.type, limit: -1}).then(res => {
        this.subPlanList = res.data.list || []
        if (this.subPlanList.length > 0) {
          // 默认选中第一个没有关联任务的计划
          const subPlan = this.subPlanList.filter(item => item.checkId === 0)
          this.form.policyPlanId = !this.form.policyPlanId && this.form.policyPlanId !== 0 ? (subPlan.length > 0 ? subPlan[0].id : '') : this.form.policyPlanId
          this.form.servePlanId = !this.form.servePlanId ? (subPlan.length > 0 ? subPlan[0].servePlanId : this.subPlanList[0].servePlanId) : this.form.servePlanId
          if (!this.mid) this.planChange(this.form.policyPlanId);
        } else {
          // 当无服务子计划时，要调用一下查询服务总计划获取到总计划id(查询是否存在服务总计划)
          this.getPlanList()
        }
      })
    },
    // 切换服务计划
    planChange() {
      const planInfo = this.subPlanList.find(plan => this.form.policyPlanId > 0 && plan.id === this.form.policyPlanId);
      if (!planInfo) return;
      // if (planInfo.checkId > 0) {
      //   const subPlan = this.subPlanList.filter(item => item.checkId === 0)
      //   if (subPlan.length > 0) {
      //     this.$Notice.warning({
      //       title: '提示',
      //       desc: '当前选择计划已关联任务,已自动切换到未关联任务的计划'
      //     });
      //     this.form.policyPlanId = subPlan[0].id
      //   } else {
      //     this.$Notice.warning({
      //       title: '提示',
      //       desc: '当前任务已无新计划,请新增计划'
      //     });
      //     this.$nextTick(() => {
      //       this.form.policyPlanId = ''
      //     })
      //     return
      //   }
      // }
      this.form.serveTime = planInfo.startAt || new Date(new Date().getTime() + 1000 * 3600 * 7);
      this.form.finishAt = planInfo.finishAt || new Date(new Date().getTime() + 30 * 24 * 3600 * 1000);
      this.form.iname =  getBaseData('子计划检查类型', this.form.type).name + '任务-' + planInfo.title;
      // 只有待发布时切换服务计划方可修改以下数据（检查标准、检查项目、检查专家）
      if(this.form.status > 1) return
      this.form.warehouseId = planInfo.warehouseId || this.form.warehouseId || ''; // 兼容判断一下若计划中未携带标准则不用更改标准id
      if (this.standardList.every(item => item.id !== this.form.warehouseId)) {
        this.$Notice.warning({
          title: '请知悉',
          desc: '当前计划所绑定的检查标准已被停用，已自动切换'
        });
        this.form.warehouseId = this.standardList[0].id
      }
      const expertList = planInfo.expertList || []
      expertList.push({
        iname: planInfo.leaderName,
        isLeader: 1,
        expertId: planInfo.leaderId
      });
      // 使用子计划中携带的专家
      this.getExpertData(expertList.reverse())
      // 根据最新warehouseId获取到最新的检查项目
      this.getStandardInfo()
    },
    // 清空已选中计划,重置
    planClear() {
      this.form.serveTime = new Date(new Date().getTime() + 1000 * 3600 * 7);
      this.form.finishAt = new Date(new Date().getTime() + 30 * 24 * 3600 * 1000);
      this.form.servePlanId = ''
      this.form.policyPlanId = ''
    },
    // 添加计划
    async addPlan() {
      if (!this.form.policyId) {
        this.$Notice.warning({
          title: '提示',
          desc: '请先选择检查项目！'
        });
        return
      }
      // 编辑时无服务计划需要直接新增服务计划,需先获取一下所需的保单数据
      if (!this.form.servePlanId) await this.getPolicy()
      // 判断，无服务总计划则打开新建总计划的窗口，若无计划明细则打开新建服务计划明细(子计划)窗口
      this.form.servePlanId ? this.subPlanFlag = true : this.planFlag = true
    },
    // 查询保单详情
    async getPolicy() {
      await policyInfo({id: this.form.policyId}).then((res) => {
        if (res.code === 200) {
          this.projectData = [res.data]
        }
      })
    },
    // 选择保单项目回调
    async getData(data) {
      this.form.iname = ''
      this.form.expertList = []
      this.projectData = data
      this.form.projectId = data[0].projectId;
      this.form.projectName = data[0].projectName;
      this.form.policyId = this.planData.length > 0 ? data[0].policyId : data[0].id;
      this.form.servePlanId = this.planData.length > 0 ? this.form.servePlanId : ''
      this.form.policyPlanId = this.planData.length > 0 ? this.form.policyPlanId : ''
      await this.getDocByProjectId()
      await this.getSubPlanList()
    },
    // 选择专家回调
    getExpertData(data) {
      data.forEach((item) => {
        if (!this.form.expertList.some(expertItem => expertItem.expertId === item.id || expertItem.expertId === item.expertId)) {
          this.form.expertList.push({
            expertId: item.id || item.expertId,
            isLeader: item.isLeader || 0,
            expertName: item.iname,
            iname: item.iname,
            cost: null,
          })
        }
      });
      if (this.form.expertList.length > 0 && !this.form.expertList.some(item => item.isLeader === 1)) this.form.expertList[0].isLeader = 1;

    },
    // 删除专家
    delExpert(index) {
      this.form.expertList.splice(index, 1)
      if (this.form.expertList.length > 0 && !this.form.expertList.some(item => item.isLeader === 1)) this.form.expertList[0].isLeader = 1;
    },
    // 新建服务计划回调
    getPlan(data1, data2, data3) {
      this.form.iname = ''
      this.form.expertList = []
      this.projectData = data3.projectData
      this.form.projectId = data3.projectData[0].projectId;
      this.form.projectName = data3.projectData[0].projectName;
      this.form.policyId = data3.projectData[0].id;
      this.form.servePlanId = ''
      this.form.policyPlanId = ''
      this.getSubPlanList()
    },
    // 新建服务子计划回调
    getSubPlan() {
      this.form.iname = ''
      this.form.expertList = []
      this.form.servePlanId = ''
      this.form.policyPlanId = ''
      this.getSubPlanList()
    },
    // 切换检查单类型
    typeChange() {
      this.form.policyPlanId = ''
      this.form.servePlanId = ''
      this.getSubPlanList()
    },
    // 任务发布
    publicTask(data) {
      publicTask({id: data.id, noExpert: true, serveTime: data.serveTime}).then(res => {
        if (res.code === 200) {
          this.close();
          this.$Notice.success({
            title: "提示！",
            desc: '操作成功！'
          });
          this.$emit('on-ok', res.data);
        } else {
          this.modal.spinShow = false;
        }
      }).catch(() => {
        this.modal.spinShow = false;
      })
    },
    // 保存
    save(mode) {
      if (!this.form.expertList || this.form.expertList.length <= 0) {
        this.$Notice.warning({
          title: '提示',
          desc: '请至少选择一位专家！'
        });
        return
      }
      this.modal.spinShow = true;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          post(this.form.id ? 'check/Edit' : 'check/Add', this.form).then((res) => {
            if (res.code === 200) {
              if (mode === 'saveAndRelease') {
                this.publicTask(res.data)
              } else {
                this.close();
                this.$Notice.success({
                  title: "提示！",
                  desc: '操作成功！'
                });
                this.$emit('on-ok', res.data);
              }

            }
          }).catch(() => {
            this.modal.spinShow = false;
          })
        } else {
          this.$Notice.error({
            title: "操作失败！",
            desc: '请检查表单是否填项正确！'
          });
          this.modal.spinShow = false;
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
.checkbox-border-item {
  margin-bottom: 10px;
  padding: 0 10px;
}

</style>
