<template>
  <div class="planEdit">
    <Form ref="form" class="editForm twoPartsEditForm" inline :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:height-200+'px',overflowY:'auto'}">
      <FormItem label="计划名称" prop="title">
        <Input v-model="form.title" placeholder="计划名称"></Input>
      </FormItem>
      <FormItem label="服务项目" prop="projectName">
        <Input v-model="form.projectName" disabled placeholder="请选择项目">
          <Button :disabled="projectData.length > 0 || !!form.id" slot="append" @click="policyFlag=true" v-if="midData.length===0" icon="ios-search">请选择</Button>
        </Input>
      </FormItem>
      <FormItem label="服务期间" prop="date">
        <ys-date-picker type="daterange" style="width: 100%" v-model="form.date" placeholder="服务期间"/>
      </FormItem>

      <FormItem label="专家组长" prop="leaderName">
        <Input readonly v-model="form.leaderName" placeholder="负责专家">
          <Button type="primary" @click="setExpert()" slot="append">选择专家</Button>
        </Input>
      </FormItem>
      <FormItem label="服务说明" style="width: 100%" prop="desc">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 1,maxRows: 2}" placeholder="服务说明"></Input>
      </FormItem>
      <h1 class="h1Title">服务计划</h1>
      <Alert v-if="form.id>0" type="warning" show-icon>注意：如需修改服务计划明细，请前往服务计划详情内进行操作。</Alert>
      <Alert v-if="!form.policyId&&!form.id" type="warning" show-icon>请先选择服务项目</Alert>
      <Row v-if="form.policyId>0">
        <i-col span="4" style="padding-left: 5px;">
          <Anchor show-ink container="#right">
            <AnchorLink :href="'#plan'+index" :title="item.name+'('+item.count+'次)'" v-for="(item,index) in form.perSubPlan" :key="index"/>
          </Anchor>
        </i-col>
        <i-col span="20">
          <div class="plan" id="right" :style="{maxHeight:height-430+'px',overflowY:'auto'}">
            <div class="planItem" :id="'plan'+index" v-for="(item,index) in form.perSubPlan" :key="item.type">
              <h1 class="h1Title">{{ item.name }}</h1>
              <FormItem label="服务次数" prop="serveCount" style="width: 100%;">
                <InputNumber :min="0" :precision="0" v-model="item.count" @on-change="calcPlan(index)" placeholder="服务次数" style="width: 100%;"></InputNumber>
              </FormItem>
              <div v-for="(subItem,subIndex) in item.list" :key="subIndex" class="list">
                <div class="index">{{ subIndex + 1 }}</div>
                <FormItem label="服务时间" prop="date1">
                  <ys-date-picker type="daterange" v-model="subItem.date" placeholder="服务时间'"/>
                </FormItem>
                <FormItem label="检查专家" prop="expertName">
                  <Input readonly v-model="subItem.expertName" @on-search="setExpert(index,subIndex)" search placeholder="检查专家"></Input>
                </FormItem>
              </div>
            </div>
          </div>
        </i-col>
      </Row>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="policyFlag" width="1000" title="服务项目保单信息">
      <policy :limit="1" :type="1" @on-ok="getData"></policy>
    </ys-modal>
    <ys-modal v-model="expertFlag" :width="1200" title="选择专家">
      <my-experts :limit="index>=0?0:1" @on-ok="getExperts"></my-experts>
    </ys-modal>
  </div>
</template>

<script>
import edit from '@/mixins/edit.js'
import Policy from "@/components/policy.vue";
import MyExperts from "@/components/experts.vue";
import {planInfo, servePlanList} from "@/api/plan";
import {formatDate} from "ys-admin";

export default {
  name: '',
  mixins: [edit],
  props: {
    projectData: {
      type: Array,
      default: () => []
    }
  },
  components: {
    MyExperts,
    Policy
  },
  data() {
    return {
      policyFlag: false,
      expertFlag: false,
      index: null,
      subIndex: null,
      form: {
        date: [],
        policyId: '',
        expertList: [],
        leaderId: '',
        policyNumber: '',
        leaderName: '',
        warehouseId: '',
        expertName: '',
        title: '',
        endAt: '',
        projectName: '',
        serveCount: 0,
        specialCount: 0,
        rescueCount: 0,
        safetyCount: 0,
        inspectsCount: 0,
        orgId: '',
        startAt: '',
        id: '',
        subPlanList: [],
        perSubPlan: [],
        desc: '',
      },
      ruleValidate: {
        title: [
          {type: 'string', required: true, message: '请填写计划名称', trigger: 'blur'}
        ],
        projectName: [
          {type: 'string', required: true, message: '请选择服务项目', trigger: 'blur'}
        ],
        date: [
          {pattern: /\S/, required: true, message: '请选择服务时间', trigger: 'blur'},
        ],
        leaderName: [
          {type: 'string', required: true, message: '请选择专家组长', trigger: 'change'}
        ],
      },
    }
  },
  async mounted() {
    if (!this.mid) { // 新增
      this.baseData['子计划检查类型'].forEach(item => {
        this.form.perSubPlan.push({name: item.name, count: 0, type: item.value, list: []})
      })
      if (this.midData && this.midData.length === 1) {
        this.form.policyId = this.midData[0].id;
        this.form.projectName = this.midData[0].projectName;
        this.form.date = [this.midData[0].startAt, this.midData[0].endAt];
        this.form.perSubPlan[1].count = this.midData[0].serverCount;
        this.calcPlan(1);
      }
    } else { // 编辑
      await planInfo({id: this.mid}).then(res => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
        this.form.date = [this.form.startAt, this.form.endAt];
        this.form.subPlanList = [];
        this.form.perSubPlan = [];
      })
    }
    if (this.projectData.length > 0) {
      await this.getData(this.projectData)
    }
  },
  methods: {
    save() {
      this.form.startAt = this.form.date[0];
      this.form.endAt = this.form.date[1];
      this.form.subPlanList = [];
      this.form.perSubPlan.forEach((item) => {
        switch (item.type) {
          case 1:
            this.form.specialCount = item.count;
            break;
          case 2:
            this.form.serveCount = item.count;
            break;
          case 3:
            this.form.rescueCount = item.count;
            break;
          case 4:
            this.form.safetyCount = item.count;
            break;
          case 5:
            this.form.inspectsCount = item.count;
            break;
        }
        item.list.forEach(subItem => {
          this.form.subPlanList.push({
            categoryList: [],
            title: subItem.title,
            expertList: subItem.expertList,
            type: subItem.type,
            startAt: subItem.date[0],
            finishAt: subItem.date[1],
          })
        })
      });
      this.ok('/servePlan/Add')
    },
    // 服务次数发生改变处理
    calcPlan(index = 0) {
      // 为null是自动同步赋值为0
      if(!this.form.perSubPlan[index].count) {
        this.$nextTick(() => {
          this.form.perSubPlan[index].count = 0
          this.form.perSubPlan[index].list = []
        })
      }
      if (this.form.perSubPlan[index] <= 0 || this.form.date.length !== 2 || !this.form.perSubPlan[index].count) return;
      this.form.perSubPlan[index].list = [];
      let diffDay = (new Date(this.form.date[1]).getTime() - new Date(this.form.date[0]).getTime()) / 3600 / 1000 / 24
      let perDay = Math.ceil(diffDay / this.form.perSubPlan[index].count);
      if (diffDay < this.form.perSubPlan[index].count - 1) {
        this.$Notice.error({
          title: '提示',
          desc: '服务天数的数值不能小于服务次数！'
        })
        return;
      }
      let startDate, endDate, start, end;
      for (let i = 0; i < this.form.perSubPlan[index].count; i++) {
        if (i === 0) {
          startDate = this.form.date[0];
        } else {
          start = new Date(this.form.perSubPlan[index].list[i - 1].date[1]);
          startDate = formatDate(new Date(start.setDate(start.getDate() + 1)), 'yyyy-MM-dd');
        }
        if (i === this.form.perSubPlan[index].count - 1) {
          endDate = this.form.date[1];
        } else {
          end = new Date(startDate);
          endDate = formatDate(new Date(end.setDate(end.getDate() + perDay)), 'yyyy-MM-dd');
        }
        this.form.perSubPlan[index].list.push({
          date: [startDate, endDate],
          title: `${this.form.projectName}第${i + 1}次${this.form.perSubPlan[index].name}服务计划`,
          type: this.form.perSubPlan[index].type,
          expertName: '',
          expertList: [],
          id: '',
        })
      }
    },
    setExpert(index, subIndex) {
      this.index = index >= 0 ? index : null;
      this.subIndex = subIndex >= 0 ? subIndex : null;
      this.expertFlag = true;
    },
    // 选择专家回调
    getExperts(data) {
      if (this.index === null) {
        this.form.leaderId = data[0].id;
        this.form.leaderName = data[0].iname;
      } else {
        this.form.perSubPlan[this.index].list[this.subIndex].expertList = data.map(item => item.id);
        this.form.perSubPlan[this.index].list[this.subIndex].expertName = data.map(item => item.iname).join(',');
      }
    },
    // 选择服务项目回调
    async getData(data) {
      let flag = true
      await servePlanList({policyId: data[0].id}).then(res => {
        if (res.data?.list && res.data?.list.length > 0) {
          flag = false
          this.$Notice.warning({
            title: '警告!',
            desc: '该保单已创建服务计划,请重新选择'
          });
        }
      });
      if (!flag) return
      this.form.projectData = data
      this.form.policyId = data[0].id;
      this.form.projectName = data[0].projectName;
      this.form.policyNumber = data[0].number;
      this.form.date = [data[0].startAt, data[0].endAt];
      this.form.perSubPlan[1].count = data[0].serverCount;
      this.calcPlan(1);
    },
  },

}
</script>

<style lang="less" scoped>
.plan {
  padding-left: 10px;

  .planItem {
    padding: 0 10px;
    margin-bottom: 10px;

    .list {
      height: 42px;
      border-radius: 30px 0 0 30px;
      padding: 3px 0 3px 40px;
      overflow-y: hidden;
      position: relative;
      margin-bottom: 10px;
      width: 100%;

      .index {
        height: 31px;
        width: 31px;
        text-align: center;
        line-height: 30px;
        position: absolute;
        border-radius: 100px;
        left: 5px;
        top: 4px;
      }
    }
  }
}
</style>
