<template>
  <div>
    <Form ref="form" class="editForm " :model="form" :rules="ruleValidate" :label-width="100">
      <FormItem label="检查标准" prop="warehouseId ">
        <Select v-model="form.warehouseId" @on-change="getStandardInfo" placeholder="检查标准">
          <Option v-for="(item) in standardList" :value="item.id" :key="item.id"> {{ item.iname }}</Option>
        </Select>
      </FormItem>
      <FormItem label="检查项目" icon="ios-browsers" prop="category" style="width: 100%">
        <CheckboxGroup v-model="form.category">
          <Checkbox v-for="(item) in categoryList" class="checkbox-border-item" border :label="item.id" :key="item.id"> {{ item.iname }}</Checkbox>
        </CheckboxGroup>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit.js'
import {getStandardInfo, getStandardData} from "@/api/common";
import {getCheckInfo} from "@/api/task";
import {all} from "ys-admin";

export default {
  name: '',
  mixins: [edit],
  props: {},
  data() {
    return {
      ruleValidate: {
        categoryList: [
          {required: true, message: '请选择需检查项目', trigger: 'blur', type: 'array'}
        ],
      },
      standardList: [],
      categoryList: [],
      form: {
        id: '',
        warehouseId: '',
        categoryList: [],
        category: [],
      }
    }
  },
  async mounted() {
    if (this.mid) { // 编辑
      await all([
        getStandardData({limit: -1, status: 1}),
        getCheckInfo({id: this.mid})
      ]).then((res) => {
        this.form.id = this.mid;
        this.standardList = res[0].data.list || [];
        this.form.warehouseId = res[1].data.warehouseId;
        this.categoryList = res[1].data.categoryList;
        this.form.category = res[1].data.categoryList.filter(item => item.enable === 1).map(item => item.id);
        this.getStandardInfo()
      })
    }
  },
  methods: {
    save() {
      this.$Modal.confirm({
        title: '提示',
        content: '请注意，同步后需要重新编辑该任务的风险数据，否则风险数据不完整甚至错误!',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          this.form.categoryList = this.categoryList.filter(item => this.form.category.indexOf(item.id) >= 0).map(item=>{
            return {categoryId:item.id,enable:1}
          });
          this.ok('/check/EditWarehouse');
        }
      })
    },
    async getStandardInfo() {
      await getStandardInfo({id: this.form.warehouseId, random: Math.ceil(Math.random() * 100)}).then(res => {
        this.categoryList = res.data.categoryList || [];
        // 默认编辑时当切换后再自动全选，或者新增直接自动全选
        if (event || !this.mid) {
          this.form.category = this.categoryList.map((item) => item.id);
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.checkbox-border-item {
  margin-bottom: 10px;
  padding: 0 10px;
}

</style>
